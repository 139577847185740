<template>
  <a-spin :tip="loadingTip" :spinning="loading">
    <div>
      <a-form-model
        ref="courseTypeForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="上级分类" prop="parentId">
          <a-cascader
            placeholder="请选择"
            :options="treeData"
            changeOnSelect
            allowClear
            v-model="form.parentId"
            :fieldNames="{ label: 'name', value: 'id', children: 'children' }"
          />
        </a-form-model-item>
        <a-form-model-item label="分类名称" prop="name">
          <a-input
            placeholder="请输入"
            v-model="form.name"
            :maxLength="15"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="是否前台显示">
          <a-switch
            v-model="form.isShow"
            checked-children="是"
            un-checked-children="否"
          />
        </a-form-model-item>
        <a-form-model-item label="状态">
          <a-switch
            v-model="form.status"
            checked-children="启用"
            un-checked-children="禁用"
          />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 10, offset: 5 }">
          <pxkeji-btn name="保存" type="primary" @click="handleSubmit" />
          <pxkeji-btn
            name="取消"
            type="default"
            @click="cancelClick"
            class="cancelBtn"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-spin>
</template>

<script>
let _this;
export default {
  props: {
    infos: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 17 },
      treeData: [],
      form: {
        parentId: ["0"],
        isShow: true,
        status: true,
      },
      rules: {
        parentId: [
          {
            required: true,
            message: "请选择上级分类",
            trigger: "change",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入分类名称",
            trigger: "blur",
          },
        ],
      },
      loading: false,
      loadingTip: "加载中...",
    };
  },
  mounted() {
    _this = this;
    _this.getTree();
    if (_this.infos.id != 0) {
      _this.getInfo();
    }
  },
  methods: {
    async getTree() {
      let result = await _this.$api.courseType.getAllTypeTree();
      if (result.errorCode == _this.$msg.responseCode.success) {
        let treeData = [
          {
            name: "根目录",
            parentId: "0",
            id: "0",
            children: result.data,
          },
        ];
        _this.treeData = treeData;
        if (_this.infos.id == 0) {
          _this.getParent();
        }
      } else {
        _this.$message.error(result.errorMsg);
      }
    },
    getParent() {
      _this.$api.courseType
        .getParentIdList(_this.infos.parentId)
        .then((res) => {
          if (res.errorCode == this.$msg.responseCode.success) {
            _this.form.parentId = _this.form.parentId.concat(res.data);
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.loading = false;
        })
        .catch(() => {
          _this.loading = false;
        });
    },
    getInfo() {
      _this.loading = true;
      _this.$api.courseType
        .getById(_this.infos.id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.form = { ...res.data, parentId: ["0"] };
            _this.form.status = _this.$commen.changeIntBoolean(res.data.status);
            _this.form.isShow = _this.$commen.changeIntBoolean(res.data.isShow);
            _this.getParent();
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.loading = false;
        })
        .catch(() => {
          _this.loading = false;
        });
    },
    handleSubmit() {
      _this.$refs.courseTypeForm.validate((valid) => {
        if (valid) {
          let formData = { ..._this.form };
          formData.status = _this.$commen.changeBooleanInt(_this.form.status);
          formData.isShow = _this.$commen.changeBooleanInt(_this.form.isShow);
          formData.parentId = formData.parentId.pop();
          _this.loading = true;
          _this.loadingTip = "保存中...";
          if (_this.infos.id != 0) {
            formData.id = _this.infos.id;
          }
          //添加
          _this.$api.courseType
            .saveOrUpdate(formData)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
                _this.$emit("callbackMethod");
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.loading = false;
            })
            .catch(() => {
              _this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    cancelClick() {
      _this.$emit("callbackMethod");
    },
  },
};
</script>
<style>
</style>