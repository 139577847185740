<template>
  <div>
    <a-spin :tip="loadingTip" :spinning="isloading">
      <div class="pxkeji-right-box">
        <pxkeji-btn name="添加一级分类" @click="showInfo(0, 0)" type="primary" :roles="['课程-课程分类添加']" />
        <a-tree
          :replaceFields="replaceFields"
          :treeData="treeData"
          showLine
          style="margin-top: 16px"
        >
          <a-icon slot="switcherIcon" type="caret-down" style="font-size: 24px" />
          <template slot="custom" slot-scope="item">
            <a-icon
              type="check-circle"
              style="margin-right: 2px; color: #52c41a"
              v-if="item.status == 1"
            />
            <a-icon type="stop" style="margin-right: 2px; color: #ff4d4f" v-else />
            <span>{{ item.name }}</span>
            <pxkeji-btn
              name="添加"
              size="small"
              @click="showInfo(0, item.id)"
              type="link"
              :roles="['课程-课程分类添加']"
            />
            <pxkeji-btn
              name="编辑"
              size="small"
              @click="showInfo(item.id, item.parentId)"
              type="link"
              :roles="['课程-课程分类修改']"
            />
            <pxkeji-btn
              name="上移"
              size="small"
              type="link"
              @click="sortCousreType(item.id, 1)"
              v-if="!item.first"
              :roles="['课程-课程分类修改']"
            />
            <pxkeji-btn
              name="下移"
              size="small"
              type="link"
              @click="sortCousreType(item.id, 0)"
              v-if="!item.last"
              :roles="['课程-课程分类修改']"
            />
            <a-popconfirm
              title="是否确认禁用此分类"
              @confirm="updateStatus(item.id, 0)"
              v-if="item.status == 1"
            >
              <a-icon slot="icon" type="question-circle-o" />
              <pxkeji-btn name="禁用" size="small" type="link" :roles="['课程-课程分类修改状态']" />
            </a-popconfirm>
            <a-popconfirm title="是否确认启用此分类" @confirm="updateStatus(item.id, 1)" v-else>
              <a-icon slot="icon" type="question-circle-o" />
              <pxkeji-btn name="启用" size="small" type="link" :roles="['课程-课程分类修改状态']" />
            </a-popconfirm>
            <a-popconfirm @confirm="delInfo(item.id)">
              <div slot="title">是否确认删除此分类？</div>
              <a-icon slot="icon" type="question-circle-o" />
              <pxkeji-btn
                name="删除"
                size="small"
                style="color: red"
                type="link"
                :loading="isloading"
                :roles="['课程-课程分类删除']"
              />
            </a-popconfirm>
          </template>
        </a-tree>
      </div>
    </a-spin>
    <a-modal
      :title="addedit.title"
      v-model="addedit.visible"
      width="800px"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <CourseTypeInfo @callbackMethod="save" :infos="addedit.info" />
    </a-modal>
  </div>
</template>
<script>
import CourseTypeInfo from './CourseTypeInfo.vue' //编辑

let _this

export default {
  name: 'courseTypeList',
  data() {
    return {
      isloading: false,
      loadingTip: '加载中...',
      replaceFields: { children: 'children', title: 'name', key: 'id' },
      treeData: [],
      addedit: {
        title: '',
        info: {},
        visible: false,
      },
    }
  },
  components: {
    CourseTypeInfo,
  },
  mounted: function () {
    _this = this
    _this.getTree()
  },
  methods: {
    async getTree() {
      let result = await _this.$api.courseType.getAllTypeTree()
      if (result.errorCode == _this.$msg.responseCode.success) {
        _this.changeTreeData(result.data)
        _this.treeData = result.data
      } else {
        _this.$message.error(result.errorMsg)
      }
    },
    changeTreeData(datas) {
      for (let key in datas) {
        datas[key].scopedSlots = { title: 'custom' }
        if (datas[key].orderNo == 1) {
          datas[key].first = true
        }
        if (datas[key].orderNo == datas[key].length) {
          datas[key].last = true
        }
        if (datas[key].children && datas[key].children.length > 0) {
          _this.changeTreeData(datas[key].children)
        }
      }
    },
    showInfo(id, parentId) {
      _this.addedit.visible = true
      _this.addedit.title = id == 0 ? '新增' : '编辑'
      _this.addedit.info = { id, parentId }
    },
    /**禁用启用的方法 */
    updateStatus(ids, status) {
      _this.isloading = true
      _this.$api.courseType
        .updateStatus(ids, status)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg)
            _this.getTree()
          } else {
            _this.$message.error(res.errorMsg)
          }
          _this.isloading = false
        })
        .catch(() => {
          _this.isloading = false
        })
    },
    /**删除 */
    delInfo(id) {
      _this.isloading = true
      _this.$api.courseType
        .removeById(id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg)
            _this.getTree()
          } else {
            _this.$message.error(res.errorMsg)
          }
          _this.isloading = false
        })
        .catch(() => {
          _this.isloading = false
        })
    },
    sortCousreType(id, type) {
      _this.isloading = true
      _this.$api.courseType
        .sortCourseType(id, type)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.getTree()
            if (res.errorMsg == '已经是顶部了') {
              _this.$message.success('已经是顶部了')
            } else if (res.errorMsg == '已经是底部了') {
              _this.$message.success('已经是底部了')
            } else {
              _this.$message.success('操作成功')
            }
          } else {
            _this.$message.error(res.errorMsg)
          }
          _this.isloading = false
        })
        .catch(() => {
          _this.isloading = false
        })
    },
    //子组件传值
    save() {
      _this.addedit.visible = false
      _this.getTree()
    },
  },
}
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.cang_sta {
  text-align: center;
}

.cang_sta img {
  width: 35px;
}
</style>

